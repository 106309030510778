import React, { useState } from 'react';
import Layout from '../components/Layout.jsx';
import PageTitle from '../components/PageTitle.jsx';
import { Box, Stack } from '@mui/material';
import { Text } from '../utils/Typography.jsx';
import { TopLeftPipe, LeftPipe, BottomLeftPipe } from '../components/Pipe.jsx';
import { GreenBox } from '../utils/Utils.jsx';

import { getIsMobile } from '../utils/Utils.jsx';
import theme from '../utils/Theme.jsx';
import Socials from '../utils/Socials.jsx';


function Contact() {
    const [isMobile, setIsMobile] = useState(getIsMobile());
    window.addEventListener("resize", () => {
        setIsMobile(getIsMobile());
    });
    const palette = theme.palette;

    function Title() {
        return <Box sx={{
            position: "relative",
            float: "left",
            width: "80%",
            marginX: "5%",
            paddingTop: "0.5rem",
            paddingBottom: "1rem",
            borderBottom: "0.1rem solid",
            borderColor: "primary.main",
        }}>
            <Text glow title sx={{
                fontSize: "1.6rem",
                float: "left",
                letterSpacing: "-0.05rem",
                transform: "scaleY(1.2)",
            }}>
                WORKSHOP
            </Text>
            <span style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                width: "60%",
                height: "0.2rem",
                backgroundColor: palette.primary.main,
                boxShadow: `0 0 0.1rem ${palette.primary.main}`,
            }} />
        </Box>;
    }

    return (
        <Layout currPage="Contact Us">
            <PageTitle upperText="Contact Us" lowerText={null} />

            <Stack direction={isMobile ? "column" : "row"} spacing={0} sx={{
                width: "100%",
                alignItems: isMobile ? "center" : "flex-start",
            }}>
                <img
                    style={{
                        width: isMobile ? "80%" : "50%",
                        padding: "2rem",
                        borderImage: `url(${process.env.PUBLIC_URL}/svg/shortborder.svg)`,
                        borderImageSlice: "10 fill",
                        boxSizing: "border-box",
                        borderStyle: "solid",
                    }}
                    src={`${process.env.PUBLIC_URL}/images/contact_us.jpg`}
                    alt="Contact Us"
                />

                <Box padding="1rem" width={isMobile ? "100%" : "50%"}>
                    <Title/>
                    <br/>
                    <Box sx={{ 
                        display: "flex",
                        width: "100%",
                        padding: "5%",
                        paddingTop: "1.5rem"
                    }}>

                        <Stack>
                            <Box display="flex" position="relative" paddingLeft="15px">
                                <TopLeftPipe/>
                                <GreenBox className="connect" whiteBox>
                                    &nbsp;LOCATION&nbsp;
                                </GreenBox>
                            </Box>
                            <Box display="flex" position="relative" paddingLeft="15px">
                                <LeftPipe/>
                                <Box sx={{
                                    paddingX: "1.75rem",
                                    paddingY: "0.5rem",
                                }}>
                                    <Text resize>
                                        Unity Centre <br/>
                                        51 Bukit Batok Crescent <br/>
                                        #07-19 <br/>
                                        Singapore 658077<br/><br/>

                                        *By appointment only, please send us a message to book a time slot.
                                    </Text>
                                </Box>
                            </Box>
                            
                            <Box position="relative" paddingLeft="15px"
                            
                            >
                                <BottomLeftPipe/>
                                <GreenBox className="connect" whiteBox sx={{
                                    marginTop: "0.65rem",
                                }}>
                                    &nbsp;SOCIALS&nbsp;
                                </GreenBox>
                                <Box sx={{
                                    paddingX: "1.75rem",
                                    paddingY: "0.5rem",
                                }}>
                                    <Stack direction="row" flexWrap={"wrap"}>
                                        <Socials socials={[ "whatsapp", "telegram", "discord", "carousell", "instagram", "tiktok", "youtube" ]}/>
                                    </Stack>
                                    <Text resize>
                                        If you have any questions, check out our&nbsp;
                                        <a href="/order" style={{ color: palette.primary.main }}>order page</a>
                                        &nbsp;or send us a message through any of these platforms!
                                    </Text>
                                </Box>
                            </Box>
                        </Stack>

                    </Box>
                </Box>
            </Stack>
        </Layout>
    );
}

export default Contact;