import React from 'react';
import { Box } from '@mui/material';
import { Text } from '../utils/Typography.jsx';


function connectElements() {
    // find all elements with class 'connect'
    const elements = document.getElementsByClassName('connect');
    const count = elements.length;

    const container = document.getElementById("connector"); // svg container
    const borderWidth = 2 / 2;

    const e1 = elements[0];
    const e2 = elements[count - 1];
    
    const y1 = e1.offsetTop + e1.offsetHeight / 2 - borderWidth;
    const y2 = e2.offsetTop + e2.offsetHeight - borderWidth;

    container.style.height = `${y2-y1 - 5}px`;
    container.style.paddingTop = `${e1.offsetHeight / 2 - borderWidth}px`;
}

function WhiteBox({ greenBox=false }) {
    return <Box sx={{
        position: "absolute",
        top: "50%", left: "0",
        transform: greenBox ? "translate(-80%, -50%)" : "translate(-25%, -50%)",
        width: "6px",
        height: "6px",
        backgroundColor: "#d7fff4",
        boxShadow: `0 0 0.5rem #2adba4`,
        zIndex: "5",
    }} />
}

function Plus({ className=null }) {
    return <>
        <WhiteBox />
        <img src={`${process.env.PUBLIC_URL}/svg/plus.svg`} alt="plus" style={{
            width: "2em",
            transform: "scale(1.5)"
        }} />
    </>;
}

function GreenBox({ children, whiteBox=false, className=null, sx=null }) {
    return <Box className={className} sx={{
        position: "relative",
        backgroundColor: "secondary.background",
        width: "fit-content",
        height: "fit-content",
        padding: "0.2em",
        borderRadius: "0.25rem",
        marginLeft: whiteBox ? "0.25em" : "0",
        ...sx
    }}>
        {whiteBox ? <WhiteBox greenBox /> : null}
        <Text short Ycenter resize>
            {children}
        </Text>
    </Box>
}

function getIsMobile() {
    return window.innerWidth < 850;
}

export { connectElements, Plus, GreenBox, getIsMobile };