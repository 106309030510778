import React from "react";

import { ReactComponent as WhatsApp } from '../components/footer_icons/whatsapp.svg';
import { ReactComponent as Telegram } from '../components/footer_icons/telegram.svg';
import { ReactComponent as Discord } from '../components/footer_icons/discord.svg';
import { ReactComponent as Carousell } from '../components/footer_icons/carousell.svg';
import { ReactComponent as Instagram } from '../components/footer_icons/instagram.svg';
import { ReactComponent as Tiktok } from '../components/footer_icons/tiktok.svg';
import { ReactComponent as Youtube } from '../components/footer_icons/youtube.svg';

const Icons = {
    whatsapp: {
        Icon: WhatsApp,
        link: "https://wa.me/qr/ZJAALAZD7G3ZO1",
    },
    telegram: {
        Icon: Telegram,
        link: "https://t.me/MarcusLC",
    },
    discord: {
        Icon: Discord,
        text: "marc0z"
    },
    carousell: {
        Icon: Carousell,
        link: "https://www.carousell.sg/lencustoms",
    },
    instagram: {
        Icon: Instagram,
        link: "https://www.instagram.com/lencustomssg/",
    },
    tiktok: {
        Icon: Tiktok,
        link: "https://www.tiktok.com/@lencustoms",
    },
    youtube: {
        Icon: Youtube,
        link: "https://www.youtube.com/@len.customs",
    }
};

function GlowIcon({Icon, link}) {
    const size = "2rem";
    return <Icon className="glow" style={{
        width: size,
        height: size,
        cursor: "pointer",
        margin: "0.9rem",
    }}
        onClick={() => {
            if (Icon === Discord) {
                navigator.clipboard.writeText(Icons.discord.text);
                alert("Username copied to clipboard!");
            } else {
                window.open(link);
            }
        }}
    />;
}


function Socials({ socials }) {
    const RenderIcons = Object.keys(Icons)
        .filter(icon => socials.includes(icon))
        .reduce((obj, key) => {
            obj[key] = Icons[key];
            return obj;
        }, {});

    console.log(RenderIcons);

    return (
        <>
            {Object.entries(RenderIcons).map(([name, {Icon, link}]) => {
                return <GlowIcon Icon={Icon} link={link} key={name} />
            })}
        </>
    );
}

export default Socials;