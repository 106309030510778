import React from 'react';
import { Box, Stack } from '@mui/material';
import Socials from '../utils/Socials.jsx';


function Footer() {
    return (
        <Box sx={{
            position: "fixed",
            left: 0,
            bottom: 0,
            width: "100vw",
            height: "4rem",
            zIndex: "50",
            
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "1rem",
            backgroundColor: "secondary.main"
        }}>
            <Stack direction="row" flexWrap={"wrap"}>
                <Socials socials={[ "whatsapp", "telegram", "carousell" ]} />
            </Stack>
        </Box>
    );
}

export default Footer;